<template>
    <div class="modal-mask video-swiper-modal">
        <div class="back-drop" @click="close()"></div>
        <div class="modal-wrapper">
            <div class="modal-container">
                <!-- <button class="modal-default-button" @click="close()">OK</button> -->
                <div class="close" @click="close()"></div>

                <div class="modal-body">
                    <slot name="body">
                        <!-- <swiper class="workSwiper" ref="mySwiper" :options="swiperOptions">
                            <swiper-slide
                                v-for="{ id, snippet: { resourceId: { videoId } } } in allVideos.items"
                                :key="id"
                            >
                                <div class="slide-content">
                                    <div class="outer mb-3">
                                        <div class="inner">
                                            <Youtube :video-id="videoId" ref="youtube"></Youtube>

                                        </div>
                                    </div>
                                    <div class="text-content"></div>
                                </div>
                            </swiper-slide>

                            <div class="swiper-button-next"></div>
                            <div class="swiper-button-prev"></div>
                        </swiper>-->

                        <div class="outer mb-3">
                            <iframe
                                class="inner"
                                :src="`https://www.youtube.com/embed/${selectedVideo.snippet.resourceId.videoId}?rel=0&ytp-pause-overlay=0`"
                                width="100%"
                                height="100%"
                                frameborder="0"
                                allowfullscreen
                                autoplay
                            ></iframe>
                        </div>
                    </slot>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { bus } from '@/main'

// import { Youtube } from 'vue-youtube'

export default {
  props: ['allVideos', 'selectedVideo'],
  data () {
    return {
      stroageUrl: process.env.VUE_APP_STORAGE_URL,
      swiperOptions: {
        initialSlide: 0,
        effect: 'fade',
        resizeObserver: true,
        navigation: {
          nextEl: '.workSwiper .swiper-button-next',
          prevEl: '.workSwiper .swiper-button-prev'
        },

        on: {
          init: (swiper) => {
          },
          beforeDestroy: (swiper) => {
          }
        }
      }
    }
  },
  computed: {
    workSwiper () {
      return this.$refs.mySwiper.$swiper
    },
    initSlide () {
      return this.allVideos.items.findIndex((el) => el.id === this.selectedVideo)
    },
    selectedVideoContent () {
      return this.allVideos.items.find(el => el.id === this.selectedVideo)
    }
  },
  methods: {
    close () {
      //   console.log('colseClicked')
      bus.$emit('closeYTModal')
    }
  },
  mounted () {

    // this.workSwiper.slideTo(this.selectedWork, 0, false)
  }
}
</script>

<style lang="scss">
.video-swiper-modal {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: table;
    transition: opacity 0.3s ease;
    .outer {
        background-color: #000;
    }
    .back-drop {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
    }

    .modal-wrapper {
        display: table-cell;
        vertical-align: middle;
    }

    .modal-container {
        width: 100vw;
        max-width: 100vw;
        margin: 0px auto;
        //   padding: 20px 30px;
        background-color: transparent;
        border-radius: 2px;
        //   box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
        transition: all 0.3s ease;
        //   font-family: Helvetica, Arial, sans-serif;
    }

    .modal-header h3 {
        margin-top: 0;
        color: #42b983;
    }

    .modal-body {
        //   margin: 20px 0;
        padding: 0;
    }

    .workSwiper {
        overflow: inherit;

        .swiper-button-next,
        .swiper-container-rtl .swiper-button-prev {
            right: -50px;
            left: auto;
            &::after {
                color: #fff;
            }
        }

        .swiper-button-prev,
        .swiper-container-rtl .swiper-button-next {
            left: -50px;
            right: auto;
            &::after {
                color: #fff;
            }
        }
    }

    .outer {
        position: relative;
        &:before {
            display: block;
            content: "";
            width: 100%;
            padding-top: (9 / 16) * 100%;
        }
        > .inner {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
        }
    }

    .swiper-pagination-bullet {
        background: #999;
        opacity: 0.8;
        &-active {
            opacity: 1;
            background: #fff;
        }
    }

    // .modal-default-button {
    //   float: right;
    // }
    .swiper-slide {
        img,
        video {
            opacity: 0;
            // height: 100%;
            width: 100%;
            object-fit: contain;
            background: #000;
        }
    }
    .swiper-slide-prev,
    .swiper-slide-next,
    .swiper-slide-active {
        img,
        video {
            transition: all 0.5s ease;
            opacity: 1;
            object-position: right;
        }
    }

    .workSwiper .text-content > * {
        opacity: 0;
        -webkit-transform: translateY(25px);
        transform: translateY(25px);
        -webkit-transition: all 0.5s ease;
        transition: all 0.5s ease;
    }

    .workSwiper .swiper-slide-active .text-content > * {
        opacity: 1;
        transform: none;
    }

    // .workSwiper .swiper-slide-active .text-content > :first-child {
    //   -webkit-transition-delay: 0.3s;
    //   transition-delay: 0.3s;
    // }

    .workInnerSwiper {
        .swiper-slide {
            display: flex;
            align-items: center;
            background: #000;
        }
    }

    @for $i from 1 through 10 {
        .workSwiper .swiper-slide-active .text-content > :nth-child(#{$i}) {
            transition-delay: #{0.2 + 0.1 * $i}s;
        }
    }

    @media (min-width: 768px) {
        .modal-container {
            width: 60vw;
        }
    }
}
</style>
